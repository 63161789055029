import React, { Fragment } from 'react' // eslint-disable-line
import axios from 'axios'
import tw, { styled } from 'twin.macro'
import { withRouter } from 'react-router'

import { PDFDocument } from 'pdf-lib' // Used for modications to underlying PDF
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import { Helmet } from 'react-helmet'

import SignHeader from './components/SignHeader'

import Notification from './components/Notification'
import Modal from './components/Modal'
import Loading from './components/Loading'
import ConsentModal from './components/ConsentModal'
import WrappedPage from './components/WrappedPage'
import base64ToArrayBuffer from './utils/base64ToBuffer'
import stampWithData from './utils/stampWithData'

import rightArrow from './icons/BYA-v2.gif'

import consentCheckbox from './icons/consentCheckbox.svg'
import consentGiven from './icons/consentGiven.svg'

import { Redirect } from 'react-router-dom'
import { getMultiple, postMultiple } from './utils/network'

const ABSTRACT_API_KEY = '028de36edd3249ca9e4cf667449e3aed'
class Multiple extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            docBase64: null,
            documentsInBase64: {}, // these are objects
            pdfDoc: null,
            pageCounts: {}, // these are objects
            pageNumber: 1,
            additionalImages: [],
            initialImage: null,
            signatureImage: null,
            initialModalShown: false,
            signatureModalShown: false,
            initialSecondaryModalShown: false,
            initialUtilityModalShown: false,
            signatureSecondaryModalShown: false,
            signatureUtilityModalShown: false,
            renderDrags: true,
            pdfCreated: false,
            notification: null,
            pdfUploaded: false,
            givenConsent: false,
            consentModalShown: true,
            scrollArrow: true,
            fieldWithArrow: null,
            groupWithArrow: null,
            width: 0,
            scale: 0,

            attemptingToSave: false,

            originalHeight: 0,
            originalWidth: 0,

            cosignerRequired: false,
            utilityHasNameOverride: false,
            leadName: '',
            utilityName: '',
            aipInfo: {},
            inApp: false,
            groups: []
        }
    }

    async componentDidMount() {
        this.updateWindowDimensions()
        window.addEventListener('resize', this.updateWindowDimensions)
        // Get params from router path
        const { proposalRecordID, types, dateCreated } = this.props.match.params
        //console.log({ location: this.props.location })
        const { location } = this.props
        if (location.search && location.search.length && location.search.includes('inApp')) {
            this.setState({ inApp: true })
        }

        const data = await getMultiple({
            proposalRecordID,
            types,
            dateCreated,
        })


        const clientActions = []
        const groups = []
        data.Documents.forEach((d, i) => {

            const clientActionsForThisDocument = []

            d.Fields.filter(f => f.type != 'QB')
                .sort((a, b) => a.top >= b.top ? 1 : -1)
                .sort((a, b) => (a.pageNumber >= b.pageNumber ? 1 : -1))
                .forEach(f => {
                    clientActionsForThisDocument.push({
                        ...f,
                        docIndex: i,
                        docType: d.Type,
                    })
                })

            const groupIdsThatNeedClicked = []
            clientActionsForThisDocument.forEach((f, i) => {
                const groupId = f.meta && f.meta.groupId ? f.meta.groupId : null
                if (f.type == 'Checkbox' && f.value && groupId && !groupIdsThatNeedClicked.includes(groupId)) {
                    groupIdsThatNeedClicked.push(groupId)
                }
            })

            clientActionsForThisDocument.forEach((f, i) => {
                console.log({ f, i })
                // another checkbox from this group has already been checked
                const groupId = f.meta && f.meta.groupId ? f.meta.groupId : null
                if (
                    (
                        groupId && groupIdsThatNeedClicked.includes(groupId)
                    ) ||
                    (
                        f.type == 'Checkbox' && f.value
                    )
                ) {
                    //already found
                    clientActionsForThisDocument[i] = {
                        ...f,
                        clicked: true
                    }
                }
            })

            clientActions.push(...clientActionsForThisDocument)

            if (d.Groups) {
                groups.push(...d.Groups)
            }
        })
        this.setState(
            {
                groups,
                clientActions,
                cosignerRequired: data.cosignerRequired,
                utilityHasNameOverride: data.utilityHasNameOverride,
                leadName: data.leadName,
                utilityName: data.utilityName,
                getTime: data.getTime,
            },
            async () => {
                // call Render PDF. Can only be called once. There is no switching now.
                await this.renderPDFs(data.Documents)
            },
        )
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions)
    }

    updateWindowDimensions = () => {
        const width = window.innerWidth
        const height = window.innerHeight

        // Revised
        const scale = width <= 380 ? 0.55 : width <= 420 ? 0.65 : width <= 767 ? 1.2 : width <= 1200 ? 1.5 : 2

        this.setState({ width, height, scale })
    }

    renderPDFs = async Documents => {
        Documents.forEach(async (d, docIndex) => {
            const arrayBuffer = base64ToArrayBuffer({ base64: d.DocumentData })
            const pdfDoc = await PDFDocument.load(arrayBuffer)
            const stampedPdfDoc = await stampWithData({
                pdfDoc,
                dataToStamp: d.Fields.filter(f => f.type == 'QB'),
            })
            const docBase64 = await stampedPdfDoc.saveAsBase64({ dataUri: true })

            this.setState({
                documentsInBase64: {
                    ...this.state.documentsInBase64,
                    [docIndex]: docBase64,
                },
            })
        })

        this.setState({ pdfCreated: true })
    }

    onDocumentLoadSuccess = ({ numPages, docIndex }) => {
        const actions = [...this.state.clientActions]
        // Filter out co-signing unless it is required
        const clientActions = actions
            .filter(a => (a.docIndex == docIndex && a.pageNumber <= numPages) || a.docIndex != docIndex)
            .filter(a => {
                if (this.state.cosignerRequired) return true
                else return a.meta.secondaryType != 'secondary'
            })

        this.setState({
            clientActions,
            pageCounts: {
                ...this.state.pageCounts,
                [docIndex]: numPages,
            },
        })
    }

    onPageOneLoadSuccess = ({ originalHeight, originalWidth }) => {
        this.setState({ originalWidth, originalHeight })
    }

    setNotification = ({ status, message, delayTime = 1500 }) => {
        this.setState(
            {
                notification: {
                    status,
                    message,
                },
            },
            () => {
                setTimeout(() => {
                    this.setState({ notification: null })
                }, delayTime)
            },
        )
    }

    savePDF = async () => {
        this.setState({ attemptingToSave: true })
        const { proposalRecordID, types, dateCreated } = this.props.match.params

        const additionalImages = []
        // strip out the base64 mimeType
        this.state.additionalImages.forEach(({ type, secondaryType, value }) => {
            additionalImages.push({ type, secondaryType, value: value.split(',')[1] })
        })

        const body = {
            proposalRecordID: parseInt(proposalRecordID),
            dateCreated: parseInt(dateCreated),
            types: types.includes(',') ? types.split(',') : [types],
            initialImage: this.state.initialImage ? this.state.initialImage.split(',')[1] : '',
            signatureImage: this.state.signatureImage ? this.state.signatureImage.split(',')[1] : '',
            additionalImages,
            clientData: JSON.stringify({
                apiInfo: this.state.apiInfo,
                getTime: this.state.getTime,
                postTime: Date.now() / 1000,
                userAgent: navigator.userAgent,
                inApp: this.state.inApp,
            }),
            // Conversion to use the fields struct.
            clientFields: this.state.clientActions.filter(f => f.type == 'FreeText' || f.type == 'Checkbox').map(f => ({
                ...f,
                value: typeof f.value == 'boolean' ? f.value ? 'set' : 'unset' : f.value,
                meta: JSON.stringify(f.meta),
            }))
        }
        try {
            const data = await postMultiple(body)
            //console.log({ data })
            if (data && data.Status == 200) {
                
                if(window.vuplex)
                {
                    window.vuplex.postMessage('DocumentsSigned');
                }

                this.setState({
                    notification: null,
                    pdfUploaded: true,
                    attemptingToSave: false,
                })
            } else {
                this.setNotification({
                    status: 'bad',
                    message: `Documents not saved.${data ? ' ' + data.ErrorMessage : ''}`,
                    delayTime: 5000,
                })
                this.setState({ attemptingToSave: false })
            }
        } catch (err) {
            this.setState({ attemptingToSave: false })
            this.setNotification({
                status: 'bad',
                message: `Documents not saved. Please Attempt again and contact support`,
                delayTime: 5000,
            })
        }
    }

    registerClick = id => {
        const { initialImage, signatureImage, additionalImages } = this.state
        if (!this.state.givenConsent) {
            this.setState({ consentModalShown: true })
            return
        }
        const fieldClicked = this.state.clientActions.find(f => f.id == id)
        const others = this.state.clientActions.filter(f => f.id != id)
        fieldClicked.clicked = true

        // Control Modal opening

        if (this.state.givenConsent) {
            // Initials
            if (fieldClicked.type == 'Initial') {
                if (fieldClicked.docType == 'nem' && this.state.utilityHasNameOverride) {
                    this.setState({
                        initialUtilityModalShown: true,
                    })
                } else if (fieldClicked.meta.secondaryType != 'secondary' && !initialImage) {
                    this.setState({
                        initialModalShown: true,
                    })
                } else if (
                    fieldClicked.meta.secondaryType == 'secondary' &&
                    !additionalImages.find(i => i.type == 'initial' && i.secondaryType == 'secondary')
                ) {
                    this.setState({
                        initialSecondaryModalShown: true,
                    })
                }
            }
            // Signatures
            else if (fieldClicked.type == 'Signature') {
                if (fieldClicked.docType == 'nem' && this.state.utilityHasNameOverride) {
                    this.setState({
                        signatureUtilityModalShown: true,
                    })
                }
                if (fieldClicked.meta.secondaryType != 'secondary' && !signatureImage) {
                    this.setState({ signatureModalShown: true })
                } else if (
                    fieldClicked.meta.secondaryType == 'secondary' &&
                    !additionalImages.find(i => i.type == 'signature' && i.secondaryType == 'secondary')
                ) {
                    this.setState({
                        signatureSecondaryModalShown: true,
                    })
                }
            }
        }

        this.setState({ clientActions: [...others, fieldClicked] }, () => {
            const { utilitySignatureIsOverride, additionalImages, initialImage, signatureImage } = this.state
            const { type, docType, meta } = fieldClicked
            // Only autoScroll on the clicks when there is already an image saved
            if (
                type == 'Initial' &&
                docType == 'nem' &&
                utilitySignatureIsOverride &&
                additionalImages.find(i => i.type == 'initial' && i.secondaryType == 'utilityOverride')
            ) {
                this.autoScroll()
            } else if (
                type == 'Signature' &&
                docType == 'nem' &&
                utilitySignatureIsOverride &&
                additionalImages.find(i => i.type == 'signature' && i.secondaryType == 'utilityOverride')
            ) {
                this.autoScroll()
            } else if (
                (type == 'Initial' &&
                    meta.secondaryType != 'secondary' &&
                    initialImage &&
                    (!utilitySignatureIsOverride || (utilitySignatureIsOverride && docType != 'nem'))) ||
                (type == 'Signature' &&
                    meta.secondaryType != 'secondary' &&
                    signatureImage &&
                    (!utilitySignatureIsOverride || (utilitySignatureIsOverride && docType != 'nem')))
            ) {
                this.autoScroll()
            }
            // secondaryType
            else if (
                type == 'Initial' &&
                meta.secondaryType == 'secondary' &&
                additionalImages.find(i => i.type == 'initial' && i.secondaryType == 'secondary')
            ) {
                this.autoScroll()
            } else if (
                type == 'Signature' &&
                meta.secondaryType == 'secondary' &&
                additionalImages.find(i => i.type == 'signature' && i.secondaryType == 'secondary')
            ) {
                this.autoScroll()
            }
        })
    }

    openConsentModal = () => {
        this.setState({ consentModalShown: true })
    }

    autoScroll = () => {
        this.setState({ scrollArrow: false })
        const yetToSign = this.state.clientActions
            .filter(a => !a.clicked)
            .filter(a => a.type != 'FreeText' || (a.type == 'FreeText' && a.meta && a.meta.required))
        if (yetToSign.length) {
            const fieldToScrollTo = yetToSign[0]
            this.setState({ fieldWithArrow: fieldToScrollTo.id })
            if (fieldToScrollTo.type == "Checkbox" && fieldToScrollTo.meta && fieldToScrollTo.meta.groupId) {
                this.setState({ groupWithArrow: fieldToScrollTo.meta.groupId })
            }
            else {
                this.setState({ groupWithArrow: null })
            }
            const scrollToElement = document.getElementById(fieldToScrollTo.id + '-scroll')
            scrollToElement.scrollIntoView({ behavior: 'smooth' })
        }
    }
    // This is the callback function provided to the modal for getting the image data
    // from the signature pad to update the global state
    updateImageFn = ({ type, data }) => {
        this.autoScroll()

        /* One of the non primary signatures
            initialSecondary
            signatureSecondary
            initialUtilityOverride
            signatureUtilityOverride
        */
        if (type.includes('Secondary') || type.includes('UtilityOverride')) {
            const newImage = { value: data }

            if (type.includes('initial')) newImage.type = 'initial'
            else newImage.type = 'signature'

            if (type.includes('Secondary')) newImage.secondaryType = 'secondary'
            else newImage.secondaryType = 'utilityOverride'

            // Get current additionalImages and filter out for copies of same type
            const additionalImages = this.state.additionalImages.filter(
                i => !(i.type == newImage.type && i.secondaryType == newImage.secondaryType),
            )
            additionalImages.push(newImage)
            this.setState({ additionalImages })
        } else {
            // This a standard primary signature
            this.setState({ [type + 'Image']: data })
        }
    }
    capitalize = s => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    scrollOffsetStyles(f) {
        const scrollOffset = 220
        return {
            opacity: 0,
            position: 'absolute',
            left: f.left * this.state.scale + 'px',
            top: ((f.top - scrollOffset) * this.state.scale).toFixed(0) + 'px',
        }
    }

    initialFieldStyles(f) {
        const { additionalImages, initialImage, scale } = this.state
        let hasImage = false
        if (
            f.meta.secondaryType == 'secondary' &&
            additionalImages.find(i => i.type == 'initial' && i.secondaryType == 'secondary')
        ) {
            hasImage = true
        } else if (f.meta.secondaryType != 'secondary') {
            hasImage = initialImage && initialImage.length > 0
        }
        return {
            position: 'absolute',
            left: f.left * scale + 'px',
            top: f.top * scale + 'px',
            height: 40 * scale + 'px',
            width: 51 * scale + 'px',
            transform: '1s',
            borderColor: hasImage && f.clicked ? 'transparent' : '',
            backgroundColor: hasImage && f.clicked ? 'transparent' : '',
        }
    }
    signatureFieldStyles(f) {
        // Remember about utility logic in here
        const { additionalImages, signatureImage, scale } = this.state
        let hasImage = false
        if (
            f.meta.secondaryType == 'secondary' &&
            additionalImages.find(i => i.type == 'signature' && i.secondaryType == 'secondary')
        ) {
            hasImage = true
        } else if (f.meta.secondaryType != 'secondary') {
            hasImage = signatureImage && signatureImage.length > 0
        }
        return {
            position: 'absolute',
            left: f.left * scale + 'px',
            top: f.top * scale + 'px',
            height: 53 * scale + 'px',
            width: 73 * scale + 'px',
            transform: '1s',
            borderColor: hasImage && f.clicked ? 'transparent' : '',
            backgroundColor: hasImage && f.clicked ? 'transparent' : '',
        }
    }
    arrowStyles(f) {
        let offset = 12;
        switch (f.type) {
            case 'Initial':
                offset = 6
                break;
            case 'FreeText':
                offset = -5
                break;
            case 'Checkbox':
                offset = -5
                break;
        }


        return {
            width: this.state.scale * 45 + 'px',
            position: 'absolute',
            left: (f.left - 45) * this.state.scale + 'px',
            top: (f.top + offset) * this.state.scale + 'px',
        }
    }

    renderInitialAction(f) {
        const { scale, initialImage, additionalImages, givenConsent } = this.state
        const scalableFontSize = 7 * this.state.scale + 'px'
        const initialImageStyles = {
            width: 40 * this.state.scale + 'px',
        }
        const editTextStyles = {
            position: 'absolute',
            bottom: -13 * this.state.scale + 'px',
            fontSize: scalableFontSize,
        }
        let modalToShow = 'initialModalShown'
        let image = ''

        if (f.docType == 'nem' && this.state.utilityHasNameOverride) {
            if (additionalImages.find(i => i.type == 'initial' && i.secondaryType == 'utilityOverride')) {
                image = additionalImages.find(i => i.type == 'initial' && i.secondaryType == 'utilityOverride').value
                modalToShow = 'initialUtilityModalShown'
            }
        }
        // Primary or secondary when not NEM and not utility override
        else {
            if (
                f.meta.secondaryType == 'secondary' &&
                additionalImages.find(i => i.type == 'initial' && i.secondaryType == 'secondary')
            ) {
                image = additionalImages.find(i => i.type == 'initial' && i.secondaryType == 'secondary').value
                modalToShow = 'initialSecondaryModalShown'
            } else if (f.meta.secondaryType != 'secondary' && initialImage) {
                image = initialImage
            }
        }

        // This has been clicked on!
        if (f.clicked && image.length > 0) {
            return (
                <>
                    <img src={image} style={initialImageStyles} />
                    <EditText
                        style={editTextStyles}
                        onClick={() =>
                            this.setState({
                                [modalToShow]: true,
                            })
                        }
                    >
                        Edit
                    </EditText>
                </>
            )
        }

        // This is a secondary Type

        return (
            <SignText
                style={{
                    fontSize: scalableFontSize,
                }}
            >
                {f.docType == 'nem' && this.state.utilityHasNameOverride
                    ? additionalImages.find(i => i.type == 'initial' && i.secondaryType == 'utilityOverride')
                        ? 'Click Utility Initials'
                        : 'Sign Utility Initials'
                    : f.meta.secondaryType == 'secondary'
                        ? additionalImages.find(i => i.type == 'initial' && i.secondaryType == 'secondary')
                            ? 'Click Co-Initials'
                            : 'Sign Co-Initials'
                        : initialImage
                            ? `Click Initials`
                            : `Sign Initials`}
            </SignText>
        )
    }

    renderSignatureAction(f) {
        const { scale, signatureImage, additionalImages, givenConsent } = this.state
        const scalableFontSize = 8 * this.state.scale + 'px'
        const signatureImageStyles = {
            width: 60 * this.state.scale + 'px',
        }
        const editTextStyles = {
            position: 'absolute',
            bottom: -13 * this.state.scale + 'px',
            fontSize: scalableFontSize,
        }

        let image = ''
        let modalToShow = 'signatureModalShown'

        if (f.docType == 'nem' && this.state.utilityHasNameOverride) {
            if (additionalImages.find(i => i.type == 'signature' && i.secondaryType == 'utilityOverride')) {
                image = additionalImages.find(i => i.type == 'signature' && i.secondaryType == 'utilityOverride').value
                modalToShow = 'signatureUtilityModalShown'
            }
        } else {
            if (
                f.meta.secondaryType == 'secondary' &&
                additionalImages.find(i => i.type == 'signature' && i.secondaryType == 'secondary')
            ) {
                image = additionalImages.find(i => i.type == 'signature' && i.secondaryType == 'secondary').value
                modalToShow = 'signatureSecondaryModalShown'
            } else if (f.meta.secondaryType != 'secondary' && signatureImage) {
                image = signatureImage
            }
        }

        // This has been clicked on!
        if (f.clicked && image.length > 0) {
            return (
                <>
                    <img src={image} style={signatureImageStyles} />
                    <EditText
                        style={editTextStyles}
                        onClick={() =>
                            this.setState({
                                [modalToShow]: true,
                            })
                        }
                    >
                        Edit
                    </EditText>
                </>
            )
        }

        // No images exist
        return (
            <SignText
                style={{
                    fontSize: scalableFontSize,
                }}
            >
                {f.docType == 'nem' && this.state.utilityHasNameOverride
                    ? additionalImages.find(i => i.type == 'signature' && i.secondaryType == 'utility')
                        ? 'Click Utility Signature'
                        : 'Sign Utility Signature'
                    : f.meta.secondaryType == 'secondary'
                        ? additionalImages.find(i => i.type == 'signature' && i.secondaryType == 'secondary')
                            ? 'Click Co-Signature'
                            : 'Sign Co-Signature'
                        : signatureImage
                            ? `Click Signature`
                            : `Sign Signature`}
            </SignText>
        )
    }

    givenConsent = async () => {
        this.setState({ givenConsent: true, consentModalShown: false })

        const url = `https://ipgeolocation.abstractapi.com/v1/?api_key=${ABSTRACT_API_KEY}`
        const res = await axios.get(url)
        if (res.status == 200) {
            this.setState({ apiInfo: res.data })
        } else {
            console.log('abstract failure')
        }
    }


    checkboxClick = (id) => {

        const { clientActions, groups } = this.state
        const specificCheckbox = clientActions.find(f => f.id == id)
        const relatedGroupId = specificCheckbox.meta && specificCheckbox.meta.groupId ? specificCheckbox.meta.groupId : null

        const specificGroup = relatedGroupId ? groups.find(g => g.id == relatedGroupId) : null

        specificCheckbox.clicked = specificCheckbox.value ? false : true
        specificCheckbox.value = !specificCheckbox.value
        const updatedCheckboxes = [specificCheckbox]

        if (specificGroup && specificGroup.type == 'OneRequired') {
            // Need to unclick all of the other checkboxes in the group
            updatedCheckboxes.push(
                ...clientActions
                    .filter(
                        f => f.id != id && f.meta && f.meta.groupId == relatedGroupId
                    )
                    .map(f => {
                        f.clicked = true
                        if (f.type == "Checkbox") {
                            f.value = false
                        }
                        else if (f.type == "FreeText") {
                            f.value = ''
                        }
                        return f
                    })
            )
        }
        else if (specificGroup && specificGroup.type == "AnyRequired" && specificCheckbox.clicked) {
            updatedCheckboxes.push(
                ...clientActions
                    .filter(
                        f => f.id != id && f.meta && f.meta.groupId == relatedGroupId
                    )
                    .map(f => {
                        f.clicked = true
                        return f
                    })
            )
        }



        const updatedCheckboxIds = updatedCheckboxes.map(c => c.id)
        console.log({ updatedCheckboxIds, updatedCheckboxes, clientActions })
        this.setState({
            clientActions: [
                ...clientActions.filter(f => !updatedCheckboxIds.includes(f.id)),
                ...updatedCheckboxes
            ]
        }, () => {
            if (specificCheckbox.clicked) {
                this.autoScroll()
            }
        })
    }

    updateFreeText = (id, value) => {
        const { clientActions } = this.state
        const specificField = clientActions.find(f => f.id == id)
        specificField.value = value
        specificField.clicked = true

        this.setState({
            clientActions: [
                ...clientActions.filter(f => f.id != specificField.id),
                specificField,
            ]
        }, () => {
            // if the user has stopped inputting text, then scroll to the next field
            setTimeout(() => {
                const fieldFromState = this.state.clientActions.find(f => f.id == id)
                if (fieldFromState && fieldFromState.value == value) {
                    this.autoScroll()
                }
            },2000)

        })

    }



    render() {
        const {
            originalWidth,
            originalHeight,
            scale,
            clientActions,
            givenConsent,
            fieldWithArrow,
            groupWithArrow,
            leadName,
            utilityName,
            utilityHasNameOverride,
            cosignerRequired,
        } = this.state

        let primaryName = ''
        let secondaryName = ''
        const nameArray = leadName.split(',')
        if (nameArray.length > 1) {
            primaryName = nameArray[0].trim()
            secondaryName = nameArray[1].trim()
        } else {
            primaryName = leadName
        }

        const viewerStyles = {
            overflowY: 'scroll',
            display: 'flex',
            justifyContent: 'flex-start',
            marginTop: this.state.width < 420 ? '85px' : '50px',
            width: '100vw',
            opacity: this.state.givenConsent ? 1 : 0.4,
            backgroundColor: '#fff',
            flexDirection: 'column',
            alignItems: 'center',
        }

        const wrapperStyles = {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
        }

        return (
            <>
                <Helmet>
                    <title>
                        {this.state.prefixA
                            ? this.capitalize(this.state.prefixA) +
                            ' ' +
                            this.capitalize(this.state.prefixB) +
                            ' Document Signing | Sunobi'
                            : 'Sunobi Document Signing'}
                    </title>
                </Helmet>
                {this.state.notification ? (
                    <Notification status={this.state.notification.status} message={this.state.notification.message} />
                ) : (
                    <></>
                )}
                {this.state.attemptingToSave ? <Loading /> : <></>}
                {this.state.pdfUploaded ? (
                    <Redirect to="/thanks" />
                ) : (
                    <>
                        <SignHeader
                            clientActions={this.state.clientActions}
                            saveFn={this.savePDF}
                            autoScrollFn={this.autoScroll}
                            width={this.state.width}
                            givenConsent={this.state.givenConsent}
                            consentModalShown={this.state.consentModalShown}
                            openConsentFn={this.openConsentModal}
                            scrollArrow={this.state.scrollArrow}
                            initialModalShown={this.state.initialModalShown}
                            signatureModalShown={this.state.signatureModalShown}
                            scale={this.state.scale}
                            attemptingToSave={this.state.attemptingToSave}
                            groups={this.state.groups}
                        />

                        <Wrapper>
                            <Container style={{ backgroundColor: this.state.pdfCreated ? '#888' : '#fff' }}>
                                {this.state.pdfCreated ? (
                                    <div style={viewerStyles} id="viewer">
                                        {Object.keys(this.state.documentsInBase64).map(i => (
                                            <Document
                                                file={this.state.documentsInBase64[i]}
                                                onLoadSuccess={props => {
                                                    const { numPages } = props
                                                    this.onDocumentLoadSuccess({
                                                        numPages,
                                                        docIndex: i,
                                                    })
                                                }}
                                                key={`document-${i}`}
                                            >
                                                {[...Array(this.state.pageCounts[i])].map((_, pageIndex) => (
                                                    <div
                                                        style={{ position: 'relative' }}
                                                        key={i + '-' + pageIndex + '-div'}
                                                    >
                                                        {pageIndex === 0 ? (
                                                            <Page
                                                                pageNumber={pageIndex + 1}
                                                                scale={scale}
                                                                key={i + '-' + pageIndex + '-page'}
                                                                onRenderSuccess={this.onPageOneLoadSuccess}
                                                                //Performance benefits
                                                                renderAnnotationLayer={false}
                                                                renderInteractiveForms={false}
                                                                renderTextLayer={false}
                                                            />
                                                        ) : (
                                                            <WrappedPage
                                                                pageNumber={pageIndex + 1}
                                                                scale={this.state.scale}
                                                                key={i + '-' + pageIndex + '-page'}
                                                                originalWidth={originalWidth}
                                                                originalHeight={originalHeight}
                                                            />
                                                        )}
                                                        <div style={wrapperStyles}>
                                                            {clientActions
                                                                .filter(
                                                                    f =>
                                                                        f.docIndex == i &&
                                                                        f.pageNumber == pageIndex + 1 &&
                                                                        (f.type == 'Initial' || f.type == 'Signature'),
                                                                )

                                                                .map(f => (
                                                                    <>
                                                                        <div
                                                                            id={`${f.id}-scroll`}
                                                                            style={this.scrollOffsetStyles(f)}
                                                                        ></div>
                                                                        {!f.clicked &&
                                                                            givenConsent &&
                                                                            f.id == fieldWithArrow ? (
                                                                            <img
                                                                                src={rightArrow}
                                                                                style={this.arrowStyles(f)}
                                                                            />
                                                                        ) : (
                                                                            <></>
                                                                        )}
                                                                        <SignField
                                                                            style={
                                                                                f.type == 'Initial'
                                                                                    ? this.initialFieldStyles(f)
                                                                                    : this.signatureFieldStyles(f)
                                                                            }
                                                                            onClick={() => this.registerClick(f.id)}
                                                                            key={`${f.id}`}
                                                                        >
                                                                            {f.type == 'Initial'
                                                                                ? this.renderInitialAction(f)
                                                                                : this.renderSignatureAction(f)}
                                                                        </SignField>
                                                                    </>
                                                                ))
                                                            }
                                                            {
                                                                clientActions
                                                                    .filter(f => f.docIndex == i && f.pageNumber == pageIndex + 1 && f.type == 'Checkbox')
                                                                    .map(f => {
                                                                        const divStyles = {
                                                                            position: 'absolute',
                                                                            left: f.left * scale + 'px',
                                                                            top: f.top * scale + 'px',
                                                                            height: 15 * scale + 'px',
                                                                            width: 15 * scale + 'px',
                                                                            transform: '1s',
                                                                            cursor: 'pointer',
                                                                        }

                                                                        return (
                                                                            <Fragment key={f.id}>
                                                                                <div id={`${f.id}-scroll`} style={this.scrollOffsetStyles(f)}></div>
                                                                                {!f.clicked && givenConsent && f.meta && f.meta.groupId == groupWithArrow && !f.meta.hide ? <img src={rightArrow} style={this.arrowStyles(f)} /> : <></>}
                                                                                <div style={divStyles} onClick={() => this.checkboxClick(f.id)}>
                                                                                    <img
                                                                                        src={f.value ? consentGiven : consentCheckbox}
                                                                                        style={{ width: f.value ? '100%' : "80%", pointerEvents: 'none' }}
                                                                                    />
                                                                                    {!f.clicked ? <CheckboxIndicator style={{ height: "85%" }} /> : <></>}
                                                                                </div>
                                                                            </Fragment>
                                                                        )
                                                                    })
                                                            }

                                                            {clientActions
                                                                .filter(f => f.docIndex == i && f.pageNumber == pageIndex + 1 && f.type == 'FreeText')
                                                                .map(f => {
                                                                    // This needs to come from meta
                                                                    const freeTextStyles = {
                                                                        position: 'absolute',
                                                                        left: f.left * scale + 'px',
                                                                        top: f.top * scale + 'px',
                                                                        width: 100 * scale + 'px',
                                                                        transform: '1s',
                                                                        cursor: 'pointer',
                                                                        borderColor: 'black',
                                                                        borderWidth: '1px',
                                                                        borderRadius: "5px"
                                                                    }
                                                                    const inputStyles = {
                                                                        zIndex: 10,
                                                                    }

                                                                    if (f.meta) {
                                                                        if (f.meta.maxWidth) {
                                                                            freeTextStyles.width = f.meta.maxWidth * scale + 'px'
                                                                        }
                                                                        if (f.meta.fontSize) {
                                                                            inputStyles.fontSize = f.meta.fontSize * scale + 'px'
                                                                        }
                                                                        if (f.meta.color) {
                                                                            inputStyles.color = 'rgb(' + f.meta.color + ')'
                                                                        }
                                                                    }

                                                                    return (
                                                                        <Fragment key={f.id}>
                                                                            <div id={`${f.id}-scroll`} style={this.scrollOffsetStyles(f)}></div>
                                                                            {!f.clicked && givenConsent && f.id == fieldWithArrow ? <img src={rightArrow} style={this.arrowStyles(f)} /> : <></>}
                                                                            <div style={freeTextStyles}>
                                                                                <Input
                                                                                    isRequired={f.meta.required}
                                                                                    value={f.value}
                                                                                    onChange={(e) => this.updateFreeText(f.id, e.target.value)}
                                                                                    style={inputStyles}
                                                                                    placeholder={f.meta.required ? 'Required Text' : 'Text'}
                                                                                />

                                                                            </div>
                                                                        </Fragment>
                                                                    )
                                                                })
                                                            }

                                                        </div>
                                                    </div>
                                                ))}
                                            </Document>
                                        ))}
                                    </div>
                                ) : (
                                    <p>Loading</p>
                                )}
                            </Container>
                        </Wrapper>

                        <Modal
                            type={'initial'}
                            show={this.state.initialModalShown}
                            hide={() => this.setState({ initialModalShown: false })}
                            updateImage={this.updateImageFn}
                            showName={cosignerRequired || utilityHasNameOverride}
                            name={primaryName}
                        />

                        <Modal
                            type={'signature'}
                            show={this.state.signatureModalShown}
                            hide={() => this.setState({ signatureModalShown: false })}
                            updateImage={this.updateImageFn}
                            showName={cosignerRequired || utilityHasNameOverride}
                            name={primaryName}
                        />

                        <Modal
                            type={'initialSecondary'}
                            show={this.state.initialSecondaryModalShown}
                            hide={() => this.setState({ initialSecondaryModalShown: false })}
                            updateImage={this.updateImageFn}
                            showName={cosignerRequired}
                            name={secondaryName}
                        />
                        <Modal
                            type={'signatureSecondary'}
                            show={this.state.signatureSecondaryModalShown}
                            hide={() => this.setState({ signatureSecondaryModalShown: false })}
                            updateImage={this.updateImageFn}
                            showName={cosignerRequired}
                            name={secondaryName}
                        />

                        <Modal
                            type={'initialUtilityOverride'}
                            show={this.state.initialUtilityModalShown}
                            hide={() => this.setState({ initialUtilityModalShown: false })}
                            updateImage={this.updateImageFn}
                            showName={utilityHasNameOverride}
                            name={utilityName}
                        />
                        <Modal
                            type={'signatureUtilityOverride'}
                            show={this.state.signatureUtilityModalShown}
                            hide={() => this.setState({ signatureUtilityModalShown: false })}
                            updateImage={this.updateImageFn}
                            showName={utilityHasNameOverride}
                            name={utilityName}
                        />

                        <ConsentModal
                            show={this.state.consentModalShown}
                            hide={() => this.setState({ consentModalShown: false })}
                            confirm={this.givenConsent}
                            scale={this.state.scale}
                        />
                    </>
                )}
            </>
        )
    }
}

const Wrapper = tw.div`
  flex flex-col items-center justify-center h-screen
`
const Container = tw.div`
  flex flex-col justify-center h-full space-y-5
`

const SignField = tw.div`
    absolute
    w-auto
    rounded-md
    flex
    flex-col
    items-center
    justify-center
    border-green-300
    bg-green-100
    bg-opacity-50
    cursor-pointer
    hover:bg-opacity-70
`
const SignText = tw.p`
    text-gray-700
    text-xs
    font-medium
`
const EditText = tw.a`
    absolute
    text-xs
    text-red-500
    font-light
    uppercase
`


const CheckboxIndicator = tw.div`
    absolute
    top-0
    border-green-300
    bg-green-100
    bg-opacity-50
    cursor-pointer
    hover:bg-opacity-70
    h-full
    w-8/10
`

const Input = styled.input(({ isRequired = false }) => [
    tw`w-full rounded py-1 px-2`,
    isRequired && tw`
        border-red-300
        bg-opacity-50
        bg-red-100
    `,
    !isRequired && tw`
        border-green-300 
        bg-green-100
        bg-opacity-50
    `
])

export default withRouter(Multiple)
